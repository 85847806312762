import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from React Router
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery for screen size detection
import HomeBG from '../images/HomeBG.jpg';
import HomeMobileBG from '../images/HomeMobileBG.jpg';
import OffersBG from '../images/OffersBG.jpg';
import OffersMobileBG from '../images/OfferMobileBG.jpg';
import DepositBG from '../images/OffersBG.jpg';
import DepositMobileBG from '../images/OfferMobileBG.jpg';


// const BackgroundImage = ({ children }) => {
//   const location = useLocation(); // Get the current location using useLocation hook
//   const [isMobile, setIsMobile] = useState(false); // Initialize isMobile state to false


//     // Condition to check if the current location is the home page
//   const [isHomePage, setIsHomePage]=useState(location.pathname === '/');
//   const [isOfferPage, setIsOfferPage]=useState(location.pathname === '/offers');
//   const [isDepositPage, setIsDepositPage]=useState(location.pathname === '/deposit');
//   const [isSubmitPage, setIsSubmitPage]=useState(location.pathname === '/submit');
//   const [isProgressPage, setIsProgressPage]=useState(location.pathname === '/progress');
//   const [isSuccessPage, setIsSuccessPage]=useState(location.pathname === '/success');


//   const mediaQuery = window.matchMedia('(max-width: 420px)');

//    // useEffect hook to set isMobile state based on media query
//    useEffect(() => {
//     mediaQuery.matches?setIsMobile(true):setIsMobile(false);

//   }, [mediaQuery]); // Run effect only once when component mounts


//   if(isHomePage){ 
//     if(isMobile){
//       return  <div className='desktop-bg' style={{ backgroundImage: `url(${HomeMobileBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
//     }else{
//       return  <div className='desktop-bg' style={{ backgroundImage: `url(${HomeBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
//     }
//   }else if(isOfferPage){
//     if(isMobile){
//       return  <div className='desktop-bg' style={{ backgroundImage: `url(${OffersMobileBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
//     }else{
//       return  <div className='desktop-bg' style={{ backgroundImage: `url(${OffersBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
//     }
//   }else if(isDepositPage){
//     if(isMobile){
//       return  <div className='desktop-bg' style={{ backgroundImage: `url(${DepositMobileBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
//     }else{
//       return  <div className='desktop-bg' style={{ backgroundImage: `url(${DepositBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
//     }
//   }else if(isSubmitPage){
//         if(isMobile){
//       return  <div className='desktop-bg' style={{ backgroundImage: `url(${DepositMobileBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
//     }else{
//       return  <div className='desktop-bg' style={{ backgroundImage: `url(${DepositBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
//     }
//   }else if(isProgressPage){
//     if(isMobile){
//   return  <div className='desktop-bg' style={{ backgroundImage: `url(${DepositMobileBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
// }else{
//   return  <div className='desktop-bg' style={{ backgroundImage: `url(${DepositBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
// }
// }else if(isSuccessPage){
//   if(isMobile){
// return  <div className='desktop-bg' style={{ backgroundImage: `url(${DepositMobileBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
// }else{
// return  <div className='desktop-bg' style={{ backgroundImage: `url(${DepositBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>{children}</div>
// }
// }

// };

const BackgroundImage = ({ children }) => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  const mediaQuery = window.matchMedia('(max-width: 420px)');

  // Update isMobile state based on media query
  useEffect(() => {
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [mediaQuery]);

  // Function to get the background image based on the current path
  const getBackgroundImage = () => {
    const path = location.pathname;

    if (path === '/') {
      return isMobile ? HomeMobileBG : HomeBG;
    } else if (path === '/offers') {
      return isMobile ? OffersMobileBG : OffersBG;
    } else if (path === '/deposit' || path === '/submit' || path === '/progress' || path === '/success') {
      return isMobile ? DepositMobileBG : DepositBG;
    }

    // Default background if no match
    return isMobile ? HomeMobileBG : HomeBG;
  };

  return (
    <div className='desktop-bg' style={{ backgroundImage: `url(${getBackgroundImage()})`, backgroundSize: 'cover', minHeight: '100vh' }}>
      {children}
    </div>
  );
};


export default BackgroundImage;
