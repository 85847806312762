import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/navbar.js';
import BackgroundImage from './Components/background.js';
import Footer from './Components/footer.js';
import Home from './Pages/Home.js';
import Offers from './Pages/Offers.js';
import Deposit from './Pages/Deposit.js';
import Submit from './Pages/SubmitDeposit.js';
import { setItem } from './Js/localstorage.js';
import Progress from './Pages/Progress.js';
import Success from './Pages/Success.js';
import HowItWorks from './Pages/How-it-works.js';

function App() {
  setItem();
  return (
    <Router scrollBehavior="auto top">
      <BackgroundImage>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/how-it-works" element={<HowItWorks />} />
          <Route exact path="/offers" element={<Offers />} />
          <Route exact path="/deposit" element={<Deposit />} />
          <Route exact path="/submit" element={<Submit />} />
          <Route exact path="/progress" element={<Progress />} />
          <Route exact path="/success" element={<Success />} />
          
        </Routes>
        <Footer />
      </BackgroundImage>
    </Router>
  );
}

export default App;
