import usa from "../images/language.png";
export const setItem = () => {

if(!localStorage.getItem("local_send_index")){
   localStorage.setItem("local_send_index", 0)
}

if(!localStorage.getItem("local_get_index")){
    localStorage.setItem("local_get_index", 1)
}

if(!sessionStorage.getItem("local_send_index")){
    sessionStorage.setItem("local_send_index", undefined)
 }
 
 if(!sessionStorage.getItem("local_get_index")){
    sessionStorage.setItem("local_get_index", undefined)
 }

if(!localStorage.getItem("local_send_amount")){
    localStorage.setItem("local_send_amount", 0.1)
}

if(!localStorage.getItem("local_get_amount")){
    localStorage.setItem("local_get_amount", 0)
}

if(!sessionStorage.getItem("local_send_amount")){
    sessionStorage.setItem("local_send_amount", undefined)
}

if(!sessionStorage.getItem("local_get_amount")){
    sessionStorage.setItem("local_get_amount", undefined)
}

if(!sessionStorage.getItem("local_send_crypto_logo")){
    sessionStorage.setItem("local_send_crypto_logo", undefined)
}

if(!sessionStorage.getItem("local_get_crypto_logo")){
    sessionStorage.setItem("local_get_crypto_logo", undefined)
}

if(!sessionStorage.getItem("local_exchange_logo")){
    sessionStorage.setItem("local_exchange_logo", undefined)
}

if(!sessionStorage.getItem("local_exchange_type")){ 
    sessionStorage.setItem("local_exchange_type", undefined) 
}

if(!sessionStorage.getItem("local_send_crypto_name")){
    sessionStorage.setItem("local_send_crypto_name", undefined)
}

if(!sessionStorage.getItem("local_get_crypto_name")){
    sessionStorage.setItem("local_get_crypto_name", undefined)
}

if(!sessionStorage.getItem("local_exchange_name")){
    sessionStorage.setItem("local_exchange_name", undefined)
}

if(!sessionStorage.getItem("local_rate_id")){
    sessionStorage.setItem("local_rate_id", undefined)
}


if(!sessionStorage.getItem("local_send_ticker")){
    sessionStorage.setItem("local_send_ticker", undefined)
}

if(!sessionStorage.getItem("local_get_ticker")){
    sessionStorage.setItem("local_get_ticker", undefined)
}

if(!localStorage.getItem("language")){
    localStorage.setItem("language", usa)
}

if(!sessionStorage.getItem('timerValue')){
    sessionStorage.setItem('timerValue', 0)
}

if(!sessionStorage.getItem('timeStamp')){
    sessionStorage.setItem('timeStamp', 0)
}

if(!sessionStorage.getItem('ordertrackerid')){
    sessionStorage.setItem('ordertrackerid', '')
}

if(!sessionStorage.getItem('depositaddress')){
    sessionStorage.setItem('depositaddress', '')
}

if(!sessionStorage.getItem('recipientaddress')){
    sessionStorage.setItem('recipientaddress', '')
}

if(!sessionStorage.getItem('txhash')){
    sessionStorage.setItem('txhash', '0x5f4e4747f1f87594e55b345cb2c3b5a9c7817a07ad8a5bb3dc5f7ed60f46aeb8')
}

if(!sessionStorage.getItem('txhashlink')){
    sessionStorage.setItem('txhashlink', '')
}

if(!sessionStorage.getItem('depositstatus')){
    sessionStorage.setItem('depositstatus', 0)
}

if(!sessionStorage.getItem('completionTime')){
    sessionStorage.setItem('completionTime', '')
}


}